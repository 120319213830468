<template>
  <b-card-text v-if="data">
    <b-row>

      <b-col
        cols="12"
        md="4"
      >
        <b-card-text>
          <h4 class="my-2">
            Remetente
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Nome:</span>
              <span>{{ data.remetente.nome }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo de Documento:
              </span>
              <span>
                {{ data.remetente.tipoDocumento }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Documento:
              </span>
              <span>
                {{ data.remetente.tipoDocumento == 'CPF' ? 
                  (data.remetente.numeroDocumento.includes('.') ? data.remetente.numeroDocumento : maskCpf(data.remetente.numeroDocumento)) : 
                  (data.remetente.numeroDocumento.includes('.') ? data.remetente.numeroDocumento : maskCnpj(data.remetente.numeroDocumento)) }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Email
              </span>
              <span>
                {{ data.remetente.email }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Telefone:
              </span>
              <span>
                {{ data.remetente.celular ? (data.remetente.celular.includes('-') ? data.remetente.celular : maskPhone(data.remetente.celular)) : '' }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">CEP:</span>
              <span>{{ data.remetente.cep }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">UF:</span>
              <span>{{ data.remetente.uf }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Cidade:</span>
              <span>{{ data.remetente.cidade }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Bairro:
              </span>
              <span>
                {{ data.remetente.bairro }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Logradouro:
              </span>
              <span>
                {{ data.remetente.endereco }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Número:
              </span>
              <span>
                {{ data.remetente.numero }} 
              </span>
            </b-row>
            <b-row
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Complemento:
              </span>
              <span>
                {{ data.remetente.complemento }} 
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>

      <b-col
        cols="12"
        md="4"
        style="border-left: 1px solid #EBE9F1"
      >
        <destinatario-component
          :destinatario="data.clienteEnderecoReversa != 0 ? data.destinatario : data.cliente"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        style="border-left: 1px solid #EBE9F1"
      >
        <b-card-text>
          <h4 class="my-2">
            Detalhes
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Serviço:
              </span>
              <span>
                {{ serviceDictionary(data.nomeServico) }}
              </span>
            </b-row>
            <b-row v-if="data.pudoId" class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Pudo:
              </span>
              <span>
                {{ data.pudoId }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Shippment ID:
              </span>
              <span>
                {{ data.shippmentId }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                JAD Code:
              </span>
              <span>
                {{ data.movimentacaoId }}
              </span>
            </b-row>
            <b-row
              v-if="data.numeroPedido"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Numero Pedido:
              </span>
              <span>
                {{ data.numeroPedido }}
              </span>
            </b-row>
            <b-row
              v-if="data.numeroDeclaracao"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Número Declaração:
              </span>
              <span>
                {{ data.numeroDeclaracao }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Valor declarado:
              </span>
              <span>
                {{ data.valorNotaFiscal ? maskCurrency(data.valorNotaFiscal) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Valor seguro:
              </span>
              <span>
                {{ data.valorParaSeguro ? maskCurrency(data.valorParaSeguro) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Qtd. volumes:
              </span>
              <span>
                {{ data.quantidade }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data geração:
              </span>
              <span>
                {{ data.dataGeracao ? createDate(data.dataGeracao) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Usuário geração:
              </span>
              <span>
                {{ data.nomeUsuarioGeracao ? data.nomeUsuarioGeracao : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data Impressão:
              </span>
              <span>
                {{ data.dataImpressao ? createDate(data.dataImpressao) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Usuário Impressão:
              </span>
              <span>
                {{ data.nomeUsuarioImpressao ? data.nomeUsuarioImpressao : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo de Geração:
              </span>
              <span>
                {{ data.metodoInsercaoTexto }}
              </span>
            </b-row>
            <b-row 
              v-if="data.dataRecebimentoEmail"
              class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data recebimento e-mail:
              </span>
              <span>
                {{ data.dataRecebimentoEmail ? maskDateHour(data.dataRecebimentoEmail) : "" }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Cancelado:
              </span>
              <span>
                {{ data.cancelado ? 'Sim' : 'Não' }}
              </span>
            </b-row>
            <b-row 
              v-if="data.cancelado"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Cancelado Data Hora:
              </span>
              <span>
                {{ data.canceladoDataHora ? maskDateHour(data.canceladoDataHora) : "" }}
              </span>
            </b-row>
            <b-row
              v-if="data.cancelado"
              class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Cancelado Usuário:
              </span>
              <span>
                {{ data.canceladoUsuario }}
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card-text>
</template>

<script>
import utils from '@/helpers/utils'
import DestinatarioComponent from './destinatario-component.vue'

export default {
  name: 'ModalVue',
  components: { DestinatarioComponent },
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      ...utils,
      mostrarDepartamento: false,
    }
  },
  methods: {
    createDate(dateString) {
      const date = new Date(dateString)
      const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }
      const output = date.toLocaleString('pt-BR', options)
      return output
    },
  },
}
</script>

<style scoped>

</style>