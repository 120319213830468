<template>
  <b-card
    no-body
    class="mb-2"
  >
    <div class="p-2">
      <b-row>
        <b-col
          v-for="(campo, index) in campos"
          :key="index"
          cols="12"
          md="3"
        >
          <validation-provider
            #default="validationContext"
            :name="campo.parametro.label"
            :vid="campo.parametro.nomeParametro"
            :rules="{ 
              required: campo.parametro.obrigatorio,
              max: campo.parametro.tamanhoMaximo,
              numeric: campo.parametro.formatoParametro === 'INTEIRO',
              regex: campo.parametro.formatoParametro === 'DATA' 
                && campo.parametro.tipoLayout === 'XML_ETIQUETA' ? /^\d{2}\/\d{2}\/\d{4}(\s\d{2}:\d{2}:\d{2})?$/ : 
                  (campo.parametro.formatoParametro === 'DATA' 
                    && campo.parametro.tipoLayout === 'CSV_ETIQUETA' ? /^\d{2}\/\d{2}\/\d{4}(\s\d{2}:\d{2}:\d{2})?$/ : 
                      (campo.parametro.nomeParametro === 'cep_destinatario' ? /^[0-9]{5}-[0-9]{3}$/ : ''))
                  
            }"
          >
            <b-form-group
              :label="campo.parametro.label"
              :label-for="campo.parametro.nomeParametro"
            >
              <b-input-group
                v-if="campo.parametro.nomeParametro === 'volumes'"
              >
                <b-form-input
                  :id="campo.parametro.nomeParametro"
                  v-model="campo.conteudo"
                  :state="validationState(validationContext)"
                  trim
                  @change="(val) => trocarValoresQuantidade(val)"
                />
              </b-input-group>
              <b-input-group
                v-else-if="campo.parametro.nomeParametro === 'valor_seguro'"
              >
                <b-form-input
                  :id="campo.parametro.nomeParametro"
                  v-model="campo.conteudo"
                  :state="validationState(validationContext)"
                  @change="(val) => trocarValoresNotaFiscal(val)"
                />
              </b-input-group>
              <b-input-group
                v-else-if="campo.parametro.nomeParametro === 'estrategia_frete'"
              >
                <b-form-input
                  :id="campo.parametro.nomeParametro"
                  v-model="campo.conteudo"
                  :state="validationState(validationContext)"
                  disabled
                />
              </b-input-group>
              <b-input-group
                v-else-if="campo.parametro.nomeParametro === 'cep_destinatario' || campo.parametro.nomeParametro === 'cep_remetente'"
              >
                <b-form-input
                  :id="campo.parametro.nomeParametro"
                  v-model="campo.conteudo"
                  v-cleave="cleaveOptions.cep"
                  :state="validationState(validationContext)"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary search-button"
                    @click="buscaEndereco(campo.conteudo)"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="14"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-input
                v-else-if="campo.parametro.nomeParametro === 'uf_destinatario' || campo.parametro.nomeParametro === 'cidade_destinatario'"
                :id="campo.parametro.nomeParametro"
                v-model="campo.conteudo"
                :state="validationState(validationContext)"
                trim
              />
              <b-form-input
                v-else-if="campo.parametro.formatoParametro !== 'DECIMAL'"
                :id="campo.parametro.nomeParametro"
                v-model="campo.conteudo"
                :state="validationState(validationContext)"
                trim
              />
              <CurrencyInput
                v-else
                v-model="campo.conteudo"
                :validation-state="validationState"
                :validation-context="validationContext"
                :msg-erro="campo.msgErro"
              />
              <b-form-invalid-feedback>
                {{ campo.msgErro ? campo.msgErro : validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import cleaveOptions from '@/libs/cleave/options'
import CurrencyInput from '@/components/form/CurrencyInput'
import CepService from '@/service/CepService'
import swal from 'sweetalert2/dist/sweetalert2.min'

export default {
  name: 'EditarDadosImportacao',
  components: { CurrencyInput },
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    item: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      campos: this.item[0].campos,
      cleaveOptions,
    }
  },
  methods: {
    validationState(context) {
      if (context.changed) {
        return this.getValidationState(context)
      }
      const result = context.valid ? null : false
      return result
    },
  },
  setup(props) {
    const trocarValoresQuantidade = valor => {
      swal.fire({
        icon: 'warning',
        text: 'Você alterou o volume, deseja alterar o peso?',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          let pesoIndex
          let volumeIndex
          props.item[0].campos.forEach((campo, index) => {
            if (campo.parametro.nomeParametro === 'peso') {
              pesoIndex = index
            }
            if (campo.parametro.nomeParametro === 'volumes') {
              volumeIndex = index
            }
          })
          const vol = props.item[volumeIndex + 1]
          const peso = props.item[pesoIndex + 1]
          const novoValor = parseFloat(vol) * (parseFloat(peso) * 1000)
          const novoPeso = parseInt(novoValor / valor, 10) / 1000
          props.item[0].campos[pesoIndex].conteudo = novoPeso
        }
      })
    }
    const trocarValoresNotaFiscal = valor => {
      let valorSeguroIndex
      let valorNotaFiscalIndex
      props.item[0].campos.forEach((campo, index) => {
        if (campo.parametro.nomeParametro === 'valor_seguro') {
          valorSeguroIndex = index
        }
        if (campo.parametro.nomeParametro === 'valor_nf') {
          valorNotaFiscalIndex = index
        }
      })
      const valorNotaFiscal = props.item[valorNotaFiscalIndex + 1]
      if (valorNotaFiscal === 0) {
        props.item[0].campos[valorNotaFiscalIndex].conteudo = valor
        return
      }
      swal.fire({
        icon: 'warning',
        html: 'Ao alterar o valor do seguro, o campo Valor da Nota Fiscal assumirá o mesmo valor. <br> Gostaria de continuar?',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          props.item[0].campos[valorNotaFiscalIndex].conteudo = valor
        } else {
          props.item[0].campos[valorSeguroIndex].conteudo = valorNotaFiscal
        }
      })
    }
    const buscaEndereco = cep => {
      const isReversa = props.item != null && props.item[0] != null 
        && props.item[0].etiqueta != null && props.item[0].etiqueta.reversa != null
        && props.item[0].etiqueta.reversa === true
      const destOuReme = isReversa ? 'remetente' : 'destinatario'
      CepService.buscarEndereco(cep).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            props.item[0].campos.forEach(campo => {
              if (campo.parametro.nomeParametro === `uf_${destOuReme}`) {
                campo.conteudo = dadosEndereco.uf
              } else if (campo.parametro.nomeParametro === `cidade_${destOuReme}`) {
                campo.conteudo = dadosEndereco.cidade
              } else if (campo.parametro.nomeParametro === `bairro_${destOuReme}`) {
                campo.conteudo = dadosEndereco.bairro
              } else if (campo.parametro.nomeParametro === `logradouro_${destOuReme}`) {
                campo.conteudo = dadosEndereco.logradouro
              }
            })
          } else {
            props.item[0].campos.forEach(campo => {
              if (campo.parametro.nomeParametro === `uf_${destOuReme}`) {
                campo.conteudo = ''
              } else if (campo.parametro.nomeParametro === `cidade_${destOuReme}`) {
                campo.conteudo = ''
              } else if (campo.parametro.nomeParametro === `bairro_${destOuReme}`) {
                campo.conteudo = ''
              } else if (campo.parametro.nomeParametro === `logradouro_${destOuReme}`) {
                campo.conteudo = ''
              }
            })
          }
        }
      })
    }

    return {
      buscaEndereco,
      trocarValoresQuantidade,
      trocarValoresNotaFiscal,
    }
  },
}
</script>

<style scoped>
.search-button {
  border: 1px solid #d8d6de !important;
}
</style>
