<template>
  <b-sidebar
    id="add-new-endereco-reversa"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Endereço Reversa
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formTratativas"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="nomeIdentificador"
            rules="required"
          >
            <b-form-group
              label="Nome Identificador Endereço Reversa *"
              label-for="nomeIdentificador"
            >
              <b-form-input
                id="nomeIdentificador"
                v-model="enderecoReversa.nomeIdentificador"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nome Identificador"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="nomeFantasia"
            rules="required"
          >
            <b-form-group
              label="Nome Destinatário Endereço Reversa *"
              label-for="nomeFantasia"
            >
              <b-form-input
                id="nomeFantasia"
                v-model="enderecoReversa.nomeFantasia"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nome Identificador"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="validationContext"
            name="tipoDocumento"
            rules="required"
          >
            <b-form-group
              label="Tipo de Documento *"
              label-for="tipoDocumento"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="enderecoReversa.tipoDocumento"
                :reduce="val=>val.value"
                :options="tipoDocumentoOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="validationContext"
            name="numeroDocumento"
            rules="required"
          >
            <b-form-group
              label="Número do Documento *"
              label-for="numeroDocumento"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="numeroDocumento"
                :key="cleaveKey"
                v-model="enderecoReversa.numeroDocumento"
                v-cleave="enderecoReversa.tipoDocumento ? cleaveOptions[enderecoReversa.tipoDocumento.toLowerCase()] : 
                  cleaveOptions.cpf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="000.000.000-00 ou 00.000.000/0000-00"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="Email *"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="enderecoReversa.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Email Reversa"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="telefone"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="enderecoReversa.telefone"
                v-cleave="cleaveOptions.phone"
                trim
                placeholder="(11) 00000-0000"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="cep"
            rules="required"
          >
            <b-form-group
              label="Cep *"
              label-for="cep"
            >
              <b-form-input
                id="cep"
                v-model="enderecoReversa.cep"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Cep Reversa"
                @blur="buscaEndereco"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="uf"
            rules="required"
          >
            <b-form-group
              label="UF *"
              label-for="uf"
            >
              <b-form-input
                id="uf"
                v-model="enderecoReversa.uf"
                readonly
                :state="getValidationState(validationContext)"
                trim
                placeholder="Estado Reversa"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="cidade"
            rules="required"
          >
            <b-form-group
              label="Cidade *"
              label-for="cidade"
            >
              <b-form-input
                id="cidade"
                v-model="enderecoReversa.cidade"
                readonly
                :state="getValidationState(validationContext)"
                trim
                placeholder="Cidade Reversa"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="bairro"
            rules="required"
          >
            <b-form-group
              label="Bairro *"
              label-for="bairro"
            >
              <b-form-input
                id="bairro"
                v-model="enderecoReversa.bairro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Bairro Reversa"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="logradouro"
            rules="required"
          >
            <b-form-group
              label="Logradouro *"
              label-for="logradouro"
            >
              <b-form-input
                id="logradouro"
                v-model="enderecoReversa.logradouro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Logradouro Reversa"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="numero"
            rules="required"
          >
            <b-form-group
              label="Número *"
              label-for="numero"
            >
              <b-form-input
                id="numero"
                v-model="enderecoReversa.numero"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Número Reversa"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="complemento"
          >
            <b-form-group
              label="Complemento"
              label-for="complemento"
            >
              <b-form-input
                id="complemento"
                v-model="enderecoReversa.complemento"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Complemento Reversa"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>

import formValidation from '@/@core/comp-functions/forms/form-validation'
import cleaveOptions from '@/libs/cleave/options'
import _ from 'lodash'
import { ref, watch, reactive, toRefs } from '@vue/composition-api'
import CepService from '@/service/CepService'
import Utils from '@/helpers/utils'
import swal from 'sweetalert2/dist/sweetalert2.min'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'EnderecoReversaSideBar',
  emits: ['aoSalvar'],
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const enderecoReversaStore = createNamespacedHelpers('enderecoReversa')
    const { save } = enderecoReversaStore.useActions(['save'])
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    const appLoading = $themeConfig.app.appLoadingImage

    const blankEnderecoReversa = {
      nomeIdentificador: null,
      nomeFantasia: null,
      tipoDocumento: null,
      numeroDocumento: null,
      email: null,
      telefone: null,
      cep: null,
      uf: null,
      cidade: null,
      bairro: null,
      logradouro: null,
      numero: null,
      complemento: null,
      clienteId,
    }
    const state = reactive({
      enderecoReversa: _.cloneDeep(blankEnderecoReversa),
    })

    const cleaveKey = ref(0)

    const tipoDocumentoOptions = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' },
    ]

    function incrementCleaveKey() {
      cleaveKey.value += 1
    }

    watch(() => state.enderecoReversa.tipoDocumento, () => {
      state.enderecoReversa.numeroDocumento = null
      incrementCleaveKey()
    })

    const reset = () => {
      state.enderecoReversa = _.cloneDeep(blankEnderecoReversa)
    }

    const onSubmit = () => {
      appLoading.show()
      try {
        const data = state.enderecoReversa
        const dataEditada = {
          ...data,
          numeroDocumento: Utils.removeSpecialCharacters(data.numeroDocumento),
          telefone: Utils.removeSpecialCharacters(data.telefone),
          cep: Utils.removeSpecialCharacters(data.cep),
        }
        save(dataEditada).then(response => {
          appLoading.hide()
          emit('aoSalvar')
          if (!response.success) {
            swal.fire({
              icon: 'warning',
              html: 'Erro ao cadastrar Endereço',
              showConfirmButton: true,
            })
          } else {
            swal.fire({
              icon: 'success',
              text: 'Endereço salva com sucesso!',
              showConfirmButton: true,
            })
          }
        })
      } catch (error) {
        appLoading.hide()
        swal.fire({
          icon: 'error',
          title: 'Erro!',
          html: `Erro ao salvar Endereço: ${error}`,
          showConfirmButton: true,
        })
      }
    }

    const buscaEndereco = event => {
      CepService.buscarEndereco(event.target.value).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            state.enderecoReversa.uf = dadosEndereco.uf
            state.enderecoReversa.cidade = dadosEndereco.cidade
          } else {
            state.enderecoReversa.uf = null
            state.enderecoReversa.cidade = null
          }
        }
      })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(reset)

    return {
      ...toRefs(state),
      ...Utils,
      buscaEndereco,
      cleaveOptions,
      cleaveKey,
      tipoDocumentoOptions,
      refFormObserver,
      onSubmit,
      getValidationState,
      resetForm,
    }
  },
}
</script>
<style>
  .undermessage-error {
  color: #FF565D;
  margin-left: 2px;
  font-size: 12px;
}
</style>