<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header class="p-2">
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Gerar Romaneio
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="p-2">
            <b-row>
              <b-col
                md="5"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Situação"
                  rules="required"
                >
                  <b-form-group
                    label="Situação"
                    label-for="situacaoId"
                  >
                    <v-select
                      id="situacaoId"
                      v-model="situacoes"
                      multiple
                      :options="situacaoOptions"
                      :reduce="val=>val.value"
                      :clearable="true"
                    />
                    <span class="undermessage-error">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
              >
                <div class="p-2">

                  <b-row class="flex-row-reverse">
                    <div style="display:flex; align-items: center; justify-content: center">
                      <b-button
                        variant="primary"
                        class="mb-sm-0 mr-0 mr-sm-1"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        type="submit"
                      >
                        Pesquisar
                        <feather-icon
                          class="ml-1"
                          icon="SearchIcon"
                        />
                      </b-button>
                    </div>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      v-show="showResultados"
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Resultados
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="m-2">
        <b-row>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
                debounce="500"
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>movimentações por página</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        show-empty
        :items="movimentacoes"
        :fields="tableColumns"
        :filter="search"
        :current-page="currentPage"
        :per-page="perPage"
        sticky-header
        :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template #head(select)="{}">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-model="allSelected"
              :disabled="selectAllDisabled"
              @change="selectAllHandler"
            />
          </div>
        </template>
        <template #cell(select)="data">
          <div
            v-if="data.item.romaneio.length > 0"
            :id="`tooltip-select-target-${data.index}`"
            :class="{['d-flex']: true, ['justify-content-center']: true, impresso: !data.item.selected && !novoRomaneioSelecionado}"
          >
            <b-checkbox
              v-model="data.item.selected"
              :disabled="novoRomaneioSelecionado"
              @change="selectHandler({ bool: data.item.selected, romaneio: data.item.romaneio})"
            />
          </div>
          <div
            v-else
            :id="`tooltip-select-target-${data.index}`"
            :class="{['d-flex']: true, ['justify-content-center']: true, impresso: false}"
          >
            <b-checkbox
              v-model="data.item.selected"
              :disabled="romaneioImpressoSelecionado || data.item.cancelado"
              @change="if (data.item.selected === false) { allSelected = false }"
            />
          </div>
          <b-tooltip 
            v-if="data.item.cancelado"
            :target="`tooltip-select-target-${data.index}`"
            triggers="hover"
          >
            <span>Etiqueta Cancelada</span>
          </b-tooltip>
        </template>
        <template #cell(situacaoRomaneio)="data">
          <b-badge
            :class="`badge badge-light-${data.item.romaneio.length > 0 ? 'success' : 'danger'}`"
          >
            {{ data.item.romaneio.length > 0 ? 'Impresso' : 'Aguardando Impressão' }}
          </b-badge>
        </template>
        <template #cell(nomeServico)="data">
          {{ serviceDictionary(data.item.nomeServico) }}
        </template>
        <template #cell(cancelar)="data">
          <b-button
            variant="link"
            :disabled="!data.item.romaneio"
            @click="deletarRomaneio( data.item.romaneio )"
          >
            <feather-icon
              v-if="!data.item.romaneio"
              icon="ClockIcon"
              size="21"
            />
            <feather-icon
              v-else
              icon="SlashIcon"
              size="21"
            />
          </b-button>
        </template>
      </b-table>
      <hr class="mt-0 mb-1">
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <validation-observer
        #default="{validate}"
        ref="impressaoValidation"
      >
        <div class="p-2 d-flex flex-row-reverse align-items-center">
          <b-button
            v-if="romaneioImpressoSelecionado"
            variant="primary"
            class="ml-2"
            @click="validate().then(reimprimirRomaneios)"
          >
            <span class="text-nowrap">REIMPRIMIR ROMANEIO</span>
          </b-button>
          <b-button
            v-else
            variant="primary"
            class="ml-2"
            @click="validate().then(imprimirRomaneios)"
          >
            <span class="text-nowrap">GERAR ROMANEIO</span>
          </b-button>
        </div>
      </validation-observer>
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes"
      ok-only
      ok-title="OK"
      size="xl"
      style="max-height: 10000px"
    >
      <details-card :data="modalData" />

    </b-modal>
    <b-modal
      ref="modalRomaneio"
      title="Impressão de Romaneio"
      ok-only
      ok-title="OK"
      size="xl"
      class="iframe-modal"
    >
      <b-row>
        <b-col
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrc"
            class="iframe"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs, watch } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import swal from 'sweetalert2/dist/sweetalert2.min'
import DateRange from '@/components/form/DateRange.vue'
import utils from '@/helpers/utils'
import DetailsCard from '../components/imprimirDetails'

export default {
  name: 'IndexVue',
  components: { 
    DetailsCard,
    DateRange,
  },
  setup() {
    const { appCarregando } = $themeConfig.app

    const romaneiosStore = createNamespacedHelpers('romaneios')
    const showResultados = ref(false)
    const modal = ref(null)
    const modalRomaneio = ref(null)
    const hoje = utils.dateRangeComTimeZone()

    const state = reactive({
      dateRange: {
        startDate: hoje,
        endDate: hoje,
      },
      movimentacoes: romaneiosStore.useGetters(['romaneios']).romaneios,
      getRomaneiosUrl: romaneiosStore.useGetters(['urlRomaneios']).urlRomaneios,
      situacoes: ['AGUARDANDO_IMPRESSAO'],
      allSelected: false,
      modalData: {},
      modalRomaneio: null,
      movimentacoesId: [],
      pdfsrc: null,
      romaneioImpressoSelecionado: false,
      novoRomaneioSelecionado: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    watch(() => state.movimentacoes, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const sendRequestRomaneios = obj => {
      state.carregando = true
      store.dispatch('romaneios/findAll', obj).then(() => {
        state.carregando = false
      })
    }

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const initialObject = {
      dataInicial: state.dateRange.startDate,
      dataFinal: state.dateRange.endDate,
      situacaoRomaneio: ['AGUARDANDO_IMPRESSAO'],
      clienteId,
    }
    sendRequestRomaneios(initialObject)

    const selectAllDisabled = ref(true)

    const checkQueryLength = () => {
      if (state.situacoes.length > 1) {
        selectAllDisabled.value = true
      } else {
        selectAllDisabled.value = false
      }
    }

    const table = {
      search: ref(''),
      sortBy: ref('nome'),
      isSortDirDesc: ref(false),
      tableColumns: [
        { key: 'select', sortable: false },
        { key: 'shippmentId', sortable: true },
        { key: 'nomeServico', sortable: true, label: 'Serviço' },
        { key: 'nomeDestinatario', sortable: true, label: 'Destinatário' },
        { key: 'destinatario.cep', sortable: true, label: 'CEP' },
        { key: 'numeroNotaFiscal', sortable: true, label: 'Nº NF', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'volume.length', sortable: true, label: 'Volumes' },
        { key: 'situacaoRomaneio', sortable: true, label: 'Situação' },
        { key: 'romaneio', sortable: true, label: 'Romaneio' },
        { key: 'cancelar', sortable: true },
      ],
    }

    const onSubmit = (show = true) => {
      checkQueryLength()
      state.currentPage = 1
      table.search.value = ''
      state.allSelected = false
      const mountObject = {
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
        situacaoRomaneio: state.situacoes,
        clienteId,
      }
      sendRequestRomaneios(mountObject)
      showResultados.value = show
      if (!show && state.movimentacoes.length > 0) {
        showResultados.value = true
      }
    }

    // Faz a pesquisa inicial assim que abre a tela
    onSubmit(false)

    const arrSelecteds = computed(() => state.movimentacoes.filter(mov => mov.selected))

    const arrNotSelecteds = computed(() => arrSelecteds.value
      .filter(x => !state.movimentacoes.includes(x))
      .concat(state.movimentacoes.filter(x => !arrSelecteds.value.includes(x))))

    const selecionaRomaneioCompleto = () => {
      // Seleciona todas as etiquetas que pertencem ao romaneio marcado
    
      // Seleciona as movimentações desmarcadas que pertencem a um romaneio escolhido (checked)
      const romaneiosSelecionados = arrSelecteds.value.map(mov => mov.romaneio)

      arrNotSelecteds.value.forEach(etiqueta => {
        if (!etiqueta.selected && romaneiosSelecionados.includes(etiqueta.romaneio)) {
          store.dispatch('romaneios/selectById', { etiquetaId: etiqueta.movimentacaoId,
            bool: true })
        }
      })
    }
    const desmarcaRomaneioCompleto = ({ romaneio }) => {
      // Desmarca as movimentações marcadas que pertencem ao romaneio que foi desmarcado
      arrSelecteds.value.forEach(etiqueta => {
        if (etiqueta.romaneio === romaneio) {
          store.dispatch('romaneios/selectById', { etiquetaId: etiqueta.movimentacaoId, bool: false })
        }
      })
    }
    const selectHandler = ({ bool, romaneio }) => {
      if (bool === false) { state.allSelected = bool }
      if (romaneio && !bool) {
        desmarcaRomaneioCompleto({ romaneio })
      }
    }

    watch(arrSelecteds,
      newValue => {
        // Verifica se a etiqueta selecionada já tem romaneio
        if (newValue.length > 0 && newValue[0].romaneio.length > 0) {
          state.romaneioImpressoSelecionado = true
          state.novoRomaneioSelecionado = false
          selecionaRomaneioCompleto()
        } else if (newValue.length > 0 && newValue[0].romaneio.length === 0) { 
          // Verifica se a etiqueta selecionada não tem romaneio
          state.romaneioImpressoSelecionado = false
          state.novoRomaneioSelecionado = true
        } else {
          // Nenhuma etiqueta selecionada
          state.romaneioImpressoSelecionado = false
          state.novoRomaneioSelecionado = false
        }
        state.movimentacoesId = newValue.map(obj => obj.movimentacaoId)
      })

    const selectAllHandler = bool => {
      store.dispatch('romaneios/selectAll', bool)
    }

    // Após o usuário selecionar um ou mais checkbox, 
    // ele deve clicar no botão verde Gerar Romaneio. 
    // Nesse momento surge o número do Romaneio e a situação passa para Impresso. 
    // Esse número do Romaneio é como se fosse o ID do Romaneio, inclusive deve ser salvo no banco. 
      
    const imprimirRomaneiosRequest = async obj => {
      await store.dispatch('romaneios/imprimirRomaneio', obj)
    }
        
    const deletarRomaneio = romaneioId => {
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: `Essa ação irá cancelar o Romaneio ${romaneioId} em todos os objetos que ele estiver envolvido! Deseja mesmo fazer isso?`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, apagar romaneio para todas as movimentações.',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appCarregando.show()
          store.dispatch('romaneios/deleteById', romaneioId).then(() => {
            appCarregando.hide()
            swal.fire({
              icon: 'success',
              html:
                '<h1>Deletado com sucesso!</h1>',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
            onSubmit()
          })
        }
      })
    }

    const openRomaneioModal = data => {
      state.modalRomaneio = {}
      state.modalRomaneio = data
      modalRomaneio.value.show()
    }

    const imprimirRomaneios = () => {
      if (state.movimentacoesId.length > 0) {
        imprimirRomaneiosRequest({ ids: state.movimentacoesId })
        openRomaneioModal(state.modalRomaneio)
        onSubmit()
      }
      if (state.movimentacoesId.length === 0) {
        swal.fire({
          icon: 'error',
          title: 'Nenhum item selecionado',
          text: 'Selecione ao menos um item da lista',
          showConfirmButton: false,
          timer: 3000,
        })
      }
    }

    const reimprimirRomaneios = async () => {
      const romaneiosIds = state.movimentacoesId.reduce((acc, id) => {
        const movimentacaoObj = state.movimentacoes.filter(obj => obj.movimentacaoId === id)
        if (acc.includes(movimentacaoObj[0].romaneio)) {
          return acc
        }
        return [...acc, movimentacaoObj[0].romaneio]
      }, [])
      await store.dispatch('romaneios/reimprimirRomaneios', { romaneiosIds })
      openRomaneioModal(state.modalRomaneio)
    }

    watch(
      () => state.getRomaneiosUrl,
      value => {
        state.pdfsrc = value
      },
    )

    const situacaoOptions = [
      { value: 'AGUARDANDO_IMPRESSAO', label: 'AGUARDANDO IMPRESSÃO' },
      { value: 'IMPRESSO', label: 'IMPRESSO' },
    ]

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }

    return {
      ...toRefs(state),
      ...utils,
      selectAllDisabled,
      situacaoOptions,
      selectHandler,
      ...table,
      selectAllHandler,
      modal,
      modalRomaneio,
      openRomaneioModal,
      deletarRomaneio,
      imprimirRomaneios,
      reimprimirRomaneios,
      showResultados,
      onSubmit,
      onFiltered,
    }
  },
}
</script>

<style lang="scss">
th {
  border-top: 0 !important;
}

.iframe-modal {
  height: 100vh;
}

.iframe {
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

// checkbox
.impresso label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute !important;
  top: 0;
  left: 0;
  background: transparent;
  border-radius: 4px; 
  border: 3px solid #F55559;
}

.impresso label:before {
  background-color: transparent !important;
  border: none;
}

.impresso input[type=checkbox] {
  visibility: hidden;
}

.swal2-cancel, .swal2-confirm {
  width: 100%;
  padding: 15px;
  margin: 5px 1rem !important;
}
// fim checkbox
</style>
