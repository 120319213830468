<template>
  <div style="padding-bottom: 80px">
    <form-wizard
      ref="refFormWizard"
      :start-index="1"
      color="#F55559"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
      transition="fade"
    >
      <tab-content
        title="Remetente"
        icon="feather icon-home"
        :before-change="() => beforeChange('remetente')"
      >
        <validation-observer
          ref="validatorRemetente"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(formWizardNextStep)"
            @reset.prevent="resetForm"
          >
            <remetente-step
              :get-validation-state="getValidationState"
              :remetente.sync="remetente"
            />
          </b-form>
        </validation-observer>
      </tab-content>
      <tab-content
        title="Destinatário"
        icon="feather icon-map-pin"
        :before-change="() => beforeChange('destinatario')"
      >
        <validation-observer
          ref="validatorDestinatario"
          #default="{handleSubmit}"
        >
          <b-form
            @submit.prevent="handleSubmit(formWizardNextStep)"
            @reset.prevent="resetForm"
          >
            <destinatario-step
              :get-validation-state="getValidationState"
              :destinatario.sync="destinatario"
            />
          </b-form>
        </validation-observer>
      </tab-content>
      <template>
        <tab-content
          title="Objetos"
          icon="feather icon-package"
          :before-change="() => beforeChange('movimentacao')"
        >
          <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <objetos-step
              ref="refObjetosStep"
              :objetos.sync="movimentacao"
              :get-validation-state="getValidationState"
              :numero-declaracao.sync="numeroDeclaracao"
              :numero-pedido.sync="numeroPedido"
              :destinatario.sync="destinatario"
              @update:numeroDeclaracao="handleNumeroDeclaracaoUpdate"
              @update:numeroPedido="handleNumeroPedidoUpdate"
            />
          </b-form>
        </tab-content>
      </template>
    </form-wizard>
    <b-modal
      ref="modalEtiqueta"
      title="Impressão de Etiqueta"
      ok-only
      ok-title="OK"
      size="xl"
      class="iframe-modal"
      @hidden="redireciona"
      @ok="redireciona"
    >
      <iframe
        :src="pdfsrc"
        class="iframe"
      />
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { reactive, ref, toRefs } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import swal from 'sweetalert2/dist/sweetalert2.min'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import RemetenteStep from './steps/remetente'
import DestinatarioStep from './steps/destinatario'
import ObjetosStep from './steps/objetos'

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'IndexVue',
  components: {
    FormWizard,
    TabContent,
    RemetenteStep,
    DestinatarioStep,
    ObjetosStep,
  },
  setup(props, { emit }) {
    const user = JSON.parse(localStorage.getItem('userName'))
    const volumesCotados = JSON.parse(localStorage.getItem('Volumes')) ? JSON.parse(localStorage.getItem('Volumes')) : []
    localStorage.removeItem('Volumes')

    const modalEtiqueta = ref(null)
    const numeroDeclaracao = ref('')
    const numeroPedido = ref('')

    const state = reactive({
      destinatario: {
        nome: '',
        clienteId: user.clienteId,
        empresa: user.unidade,
        salvar: true,
      },
      remetente: {},
      movimentacao: {
        dataNotaFiscal: '',
        serieNotaFiscal: '',
        valorNotaFiscal: null,
        chaveNotaFiscal: '',
        numeroNotaFiscal: '',
        conteudo: [],
        volumes: volumesCotados,
        metodoInsercao: 'CLIENTE_MANUAL',
        nomeUsuarioGeracao: user.nome,
        reversa: false,
        numeroPedido: null,
        numeroDeclaracao: null,
      },
      salvarRemetente: true,
      salvarDestinatario: true,
      modalEtiqueta: null,
      pdfsrc: '',
    })

    const reset = () => {
      state.destinatario = {
        nome: '',
        clienteId: user.clienteId,
        empresa: user.unidade,
        salvar: true,
      }
      state.remetente = {
        nome: '',
      }
      state.movimentacao = {
        dataNotaFiscal: '',
        serieNotaFiscal: '',
        valorNotaFiscal: null,
        chaveNotaFiscal: '',
        numeroNotaFiscal: '',
        conteudo: [],
        volumes: [],
        metodoInsercao: 'CLIENTE_MANUAL',
        reversa: false,
      }
    }

    const refFormWizard = ref(null)
    const validatorRemetente = ref(null)
    const validatorDestinatario = ref(null)
    const validatorObjetos = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    const refObjetosStep = ref(null)

    const openEtiquetaModal = data => {
      state.pdfsrc = data
      modalEtiqueta.value.show()
    }

    const handleNumeroDeclaracaoUpdate = value => {
      state.movimentacao.numeroDeclaracao = value
    }

    const handleNumeroPedidoUpdate = value => {
      state.movimentacao.numeroPedido = value
    }

    const postData = () => {
      appLoading.show()
      if (state.destinatario.salvar) {
        store.dispatch('destinatario/save', state.destinatario)
      }
      store.dispatch('etiqueta/save', state.movimentacao).then(response => {
        appLoading.hide()
        if (response.status === 201) {
          emit('refresh')
          emit('update:active', false)
          swal.fire({
            html: `<h3>${response.body} etiqueta(s) gerada(s) com sucesso!`
                  + ' Clique em OK para ser direcionado para impressão.</h3>',
            icon: 'success',
            showCancelButton: true,
            customClass: {
              cancelButton: 'ml-1',
            },
            confirmButtonText: 'Ok, Imprimir Etq',
            cancelButtonText: 'Ok, Gerar Nova Etq',
          }).then(result => {
            if (!result.isConfirmed) {
              router.push('/envios/etiquetas/etq-manual')
            } else {
              router.push('/envios/imprimir-etiquetas/true')
            }
          })
        } else {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: response.body,
                icon: 'AlertCircleIcon',
                variant: 'primary',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      })
    }

    const saveState = () => {
      state.movimentacao.destinatario = state.destinatario
      state.movimentacao.remetente = state.remetente
      if (
        (!state.movimentacao.numeroNotaFiscal || !state.movimentacao.valorNotaFiscal)
        && state.movimentacao.conteudo.length === 0
      ) {
        Vue.$toast(
          {
            component: ToastificationContent,
            props: {
              title:
                'Você precisa declarar conteúdo ou fornecer número e valor da Nota Fiscal!',
              icon: 'AlertCircleIcon',
              variant: 'primary',
            },
          },
          {
            timeout: 5000,
            draggable: true,
            hideProgressBar: false,
          },
        )
        return
      }
      if (state.movimentacao.volumes.length === 0) {
        Vue.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Você precisa inserir pelo menos um volume!',
              icon: 'AlertCircleIcon',
              variant: 'primary',
            },
          },
          {
            timeout: 5000,
            draggable: true,
            hideProgressBar: false,
          },
        )
        return
      }
      if (state.movimentacao.servicoId === undefined) {
        Vue.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Você precisa escolher um serviço!',
              icon: 'AlertCircleIcon',
              variant: 'primary',
            },
          },
          {
            timeout: 5000,
            draggable: true,
            hideProgressBar: false,
          },
        )
        return
      }
      if (state.movimentacao.servicoId === 40 && state.movimentacao.pudoId === undefined) {
        Vue.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Você precisa escolher um pudo para o serviço PickUP!',
              icon: 'AlertCircleIcon',
              variant: 'primary',
            },
          },
          {
            timeout: 5000,
            draggable: true,
            hideProgressBar: false,
          },
        )
        return
      }
      postData()
    }

    const onSubmit = () => {
      saveState()
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(reset)

    const beforeChange = entity => {
      switch (entity) {
        case 'remetente':
          return validatorRemetente.value.validate()
        case 'destinatario':
          return validatorDestinatario.value.validate()
        case 'movimentacao':
          return validatorObjetos.value.validate()
        default:
          return refFormObserver.value.validate()
      }
    }

    const redireciona = () => {
      router.push('/envios/imprimir-etiquetas/true').catch(() => {})
    }

    return {
      // DATA
      ...toRefs(state),
      volumesCotados,
      // METHODS
      onSubmit,
      beforeChange,
      handleNumeroDeclaracaoUpdate,
      handleNumeroPedidoUpdate,
      // FORM
      refFormObserver,
      refObjetosStep,
      validatorRemetente,
      validatorDestinatario,
      validatorObjetos,
      getValidationState,
      resetForm,
      numeroPedido,
      numeroDeclaracao,
      // FORM WIZARD
      refFormWizard,
      formWizardNextStep,
      modalEtiqueta,
      openEtiquetaModal,
      redireciona,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}

.wizard-tab-content {
  display: flex;

  .wizard-tab-container {
    display: block;
    animation: fadeInRight 0.3s;
  }
}
</style>
