<template>
  <b-card-text>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="px-3"
      >
        <b-card-text>
          <h4 class="my-2">
            Destinatário
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Nome:</span>
              <span>{{ data.cliente.nome }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo de Documento
              </span>
              <span>
                {{ data.cliente.cnpj ? (data.cliente.cnpj.length === 11 ? 'CPF' : 'CNPJ') : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Documento:
              </span>
              <span>
                {{ data.cliente.cnpj ? (data.cliente.cnpj.length === 11 ? maskCpf(data.cliente.cnpj) : maskCnpj(data.cliente.cnpj)) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Email
              </span>
              <span>
                {{ data.cliente.email }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Telefone:
              </span>
              <span>
                {{ data.cliente.fone }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">CEP:</span>
              <span>{{ data.cliente.cep ? maskCep(data.cliente.cep).replace('.','') : '' }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">UF:</span>
              <span>{{ data.cliente.uf }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Cidade:</span>
              <span>{{ data.cliente.cidade }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Bairro:
              </span>
              <span>
                {{ data.cliente.bairro }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Logradouro:
              </span>
              <span>
                {{ data.cliente.endereco }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Número:
              </span>
              <span>
                {{ data.cliente.numero }} 
              </span>
            </b-row>
            <b-row
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Complemento:
              </span>
              <span>
                {{ data.cliente.complemento }} 
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="px-3"
        style="border-left: 1px solid #EBE9F1"
      >
        <b-card-text>
          <h4 class="my-2">
            Detalhes
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Pedido na Loja:
              </span>
              <span>
                {{ data.numeroPedidoLoja }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data do Pedido:
              </span>
              <span>
                {{ data.data ? maskDate(data.data) : '' }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Nota Fiscal:
              </span>
              <span>
                {{ data.nota ? data.nota.numero : '' }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data NF:
              </span>
              <span>
                {{ data.nota && data.nota.dataEmissao ? maskDate(data.nota.dataEmissao) : '' }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                ShippmentID:
              </span>
              <span>
                {{ data.shippmentId }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Serviço:
              </span>
              <span>
                {{ getServico(data) }} 
              </span>
            </b-row>
            <b-row v-if="data.pudoId" class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Pudo:
              </span>
              <span>
                {{ data.pudoId }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Valor Nota Fiscal:
              </span>
              <span>
                {{ data.nota && data.nota.valorNota ? maskCurrency(Number(data.nota.valorNota)) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Valor Seguro:
              </span>
              <span>
                {{ data.nota && data.nota.valorNota ? maskCurrency(Number(data.nota.valorNota)) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Qtd. volumes:
              </span>
              <span>
                {{ data.itens ? data.itens.length : '' }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo Integração:
              </span>
              <span>
                {{ data.tipoIntegracao }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Situação:
              </span>
              <span>
                {{ tratarSituacao(data.situacao) }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Observação:
              </span>
              <span>
                {{ data.observacoes }} 
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card-text>
</template>

<script>
import utils from '@/helpers/utils'

export default {
  name: 'ModalVue',
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  data() {
    const getServico = data => {
      if (data.transporte && data.transporte.volumes && data.transporte && data.transporte.volumes.length > 0) {
        if (data.transporte.volumes[0].volume != null) {
          return data.transporte.volumes[0].volume.servico
        }
        if (data.transporte.volumes[0].servico) {
          return data.transporte.volumes[0].servico
        }
      }
      return ''
    }

    const tratarSituacao = situacao => {
      if (situacao !== null && situacao.toString().length < 3) {
        return utils.blingV3SituacaoDictionary(situacao)
      } return situacao
    }

    return {
      ...utils,
      tratarSituacao,
      getServico,
    }
  },
}
</script>

<style scoped>

</style>