<template>
  <div>
    <add-integracao-sidebar
      :active.sync="isAddIntegracoesSidebarActive"
      @aoSalvar="atualizaLista"
    />
    <b-card
      no-body
      class="mb-3"
    >
      <b-row>
        <b-col 
          cols="8"
          class="d-flex justify-content-start"
        >
          <b-card-header>
            <b-card-title class="font-medium-2 card-title">
              <span>
                Adicionar integração ou carregar vendas clicando em um ERP já adicionado
              </span>
            </b-card-title>
          </b-card-header>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-end"
        >
          <div class="p-2">
            <b-button
              variant="primary"
              class="mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="isAddIntegracoesSidebarActive = true"
            >
              Adicionar integração
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="my-2">
      <b-col
        v-for="(loja, index) in lojas"
        :key="index"
        cols="4"
      >
        <loja-card
          :loja="loja"
          :url-logo-integracao="urlLogoIntegracao"
          :confirma-exclusao="confirmaExclusao"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { $themeConfig } from '@themeConfig'
import swal from 'sweetalert2/dist/sweetalert2.min'
import AddIntegracaoSidebar from './components/add-integracao-sidebar'
import LojaCard from './components/card/lojaCard.vue'

export default {
  name: 'GerarEtiquetasIntegracao',
  components: { AddIntegracaoSidebar, LojaCard },
  setup() {
    const { appCarregando } = $themeConfig.app
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    const currentPath = router.currentRoute.query

    const integracaoStore = createNamespacedHelpers('integracao')
    const lojaStore = createNamespacedHelpers('loja')

    const state = reactive({
      integracoes: integracaoStore.useGetters(['integracoes']).integracoes,
      lojas: lojaStore.useGetters(['lojas']).lojas,
      isAddIntegracoesSidebarActive: false,
    })

    onBeforeMount(() => {
      store.dispatch('loja/findAll', clienteId)
      store.dispatch('integracao/findAll').then(() => {
        if (currentPath.code != null) {
          const blingV3 = state.lojas.find(i => i.integracaoId === 2)
          if (blingV3) {
            swal.fire({
              title: 'Atenção!',
              icon: 'warning',
              html: 'Voçe já possui a integração bling V3',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              confirmButtonText: 'OK',
              buttonsStyling: false,
            })
          } else {
            swal.fire({
              title: 'Atenção!',
              icon: 'warning',
              html: 'Deseja adicionar a integração Bling V3 ao seu usuário?',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Sim',
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                appCarregando.show()
                const integracaoAuth = {
                  integracaoId: 2,
                  clienteId,
                  code: currentPath.code,
                }
                store.dispatch('loja/saveAuth', integracaoAuth).then(resp => {
                  appCarregando.hide()
                  if (resp.success) {
                    swal.fire({
                      icon: 'success',
                      title: 'Sucesso',
                      text: 'Bling V3 cadastrado!',
                      showConfirmButton: false,
                      timer: 3000,
                    }).then(() => {
                      router.push('/envios/etiquetas/integracao')
                    })
                  } else {
                    swal.fire({
                      title: 'Erro!',
                      icon: 'error',
                      html: `${resp.body}`,
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      showCancelButton: false,
                      confirmButtonText: 'OK',
                      buttonsStyling: false,
                    })
                  }
                })
              }
            })
          }
        }
      })
    })

    const atualizaLista = () => {
      appCarregando.show()
      store.dispatch('loja/findAll', clienteId).then(() => appCarregando.hide())
    }

    const urlLogoIntegracao = integracaoId => {
      const ar = state.integracoes.find(i => i.integracaoId === integracaoId)
      if (ar) {
        return ar.urlLogo
      }
      return null
    }

    const confirmaExclusao = lojaId => {
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Deseja mesmo excluir esse cadastro de integração?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          appCarregando.show()
          store.dispatch('loja/delete', lojaId).then(() => {
            atualizaLista()
            Vue.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Integração excluída com sucesso!',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              },
              {
                timeout: 5000,
                draggable: true,
                hideProgressBar: false,
              },
            )
          })
        }
      })
    }

    return {
      ...toRefs(state),
      atualizaLista,
      urlLogoIntegracao,
      confirmaExclusao,
    }
  },
}
</script>

<style>

</style>