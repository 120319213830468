import PickUpService from '@/service/PickUpService'

export default {
  namespaced: true,
  state: {
    pickUps: [],
  },
  getters: {
    getPickUps(state) {
      return state.pickUps
    },
  },
  mutations: {
    UPDATE_PICKUPS(state, pickUps) {
      state.pickUps = pickUps
    },
  },
  actions: {
    async findPickUps({ commit }, { clienteId, cep }) {
      const pickUps = await PickUpService.findPickUps(clienteId, cep)
      commit('UPDATE_PICKUPS', pickUps)
      return pickUps
    },
  },
}