import httpClient from './http-clients/portaljad'

const baseUrl = 'pick-up'

export default class PickUpService {
  static async findPickUps(clienteId, cep) {
    try {
      const { data } = await httpClient.get([baseUrl, clienteId, cep].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}