<template lang="">
  <div>
    <!-- <b-card>
      <b-row align-h="between">
        <b-col cols="6">
          <b-form-group
            label="Cep pick up"
            label-for="pickupCep"
          >
            <b-form-input
              id="pickupCep"
              v-model="cep"
              trim
              placeholder="00000-000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <div class="pt-2">
            <b-button
              variant="primary"
              class="w-100 d-block"
              type="button"
              style="max-height:38px;"
              @click="buscarPickUp()"
            >
              Buscar Pick up
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card> -->
    <b-card>
      <div v-if="campos.length > 0">
        <div
          v-for="campo in campos"
          :key="campo.pudoId"
        >
          <b-row class="align-items-center mb-2">
            <b-col
              cols="1"
              class="mr-1"
            >
              <b-form-checkbox
                :id="'checkbox-' + campo.pudoId"
                :checked="isCampoSelected(campo.pudoId)"
                @change="toggleCampoSelection(campo)"
              />
            </b-col>
            <b-col
              cols="10"
              class="d-flex flex-column"
            >
              <span><strong>PudoId: </strong> {{ campo.pudoId }}</span>
              <span><strong>Razão Social: </strong> {{ campo.razao }}</span>
              <b-form-group class="mt-1">
                <b-form-checkbox
                  v-for="(end, index) in campo.enderecos"
                  :key="index + '-' + campo.pudoId"
                  :checked="isEnderecoSelected(end)"
                  :disabled="!isCampoSelected(campo.pudoId)"
                  @change="toggleEnderecoSelection(end)"
                >
                  <span>{{ end.endereco }}, {{ end.numero }}, {{ end.bairro }}, {{ end.cidade }}/{{ end.uf }}</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div></div>
      <b-col v-else>
        Nenhum resultado encontrado.
      </b-col>
    </b-card>
  </div>
</template>
<script>

import store from '@/store'
import { reactive, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'PickupModal',
  props: {
    cepDest: {
      type: undefined,
      required: true,
    },
    selected: {
      type: undefined,
      default: null,
    },
    selectedEnd: {
      type: undefined,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const pickupStore = createNamespacedHelpers('pickup')

    const state = reactive({
      cep: props.cepDest || '',
      campos: pickupStore.useGetters(['getPickUps']).getPickUps,
      localSelected: props.selected || null,
      localSelectedEnd: props.selectedEnd || null,
    })
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    watch(() => state.localSelected, selected => {
      if (selected && selected.enderecos.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        state.localSelectedEnd = selected.enderecos[0]
        emit('update:selectedEnd', state.localSelectedEnd)
        if (props.data) {
          props.data.item[0].etiqueta.pudoId = selected.pudoId
        }
      } else {
        state.localSelectedEnd = null
        if (props.data) {
          props.data.item[0].etiqueta.pudoId = null
        }
      }
    })

    const toggleCampoSelection = campo => {
      if (state.localSelected && state.localSelected.pudoId === campo.pudoId) {
        state.localSelected = null
        state.localSelectedEnd = null
        emit('update:selected', null)
        emit('update:selectedEnd', null)
      } else {
        state.localSelected = campo
        emit('update:selected', campo)
      }
    }

    const toggleEnderecoSelection = end => {
      if (state.localSelectedEnd === end) {
        state.localSelectedEnd = null
      } else {
        state.localSelectedEnd = end
      }
      emit('update:selectedEnd', state.localSelectedEnd)
    }

    const isCampoSelected = pudoId => state.localSelected && state.localSelected.pudoId === pudoId

    const isEnderecoSelected = end => state.localSelectedEnd === end

    const buscarPickUp = () => {
      appLoading.show()
      store.dispatch('pickup/findPickUps', { clienteId, cep: state.cep }).finally(() => {
        appLoading.hide()
      })
    }
    return {
      ...toRefs(state),
      buscarPickUp,
      isCampoSelected,
      isEnderecoSelected,
      toggleCampoSelection,
      toggleEnderecoSelection,
    }
  },
}
</script>
<style lang="">
  
</style>