import { extend, localize } from 'vee-validate'
import {
  alpha as ruleAlpha,
  alpha_dash as ruleAlphaDash,
  alpha_num as ruleAlphaNum,
  alpha_spaces as ruleAlphaSpaces,
  between as ruleBetween,
  confirmed as ruleConfirmed,
  digits as ruleDigits,
  email as ruleEmail,
  integer as ruleInteger,
  length as ruleLength,
  min as ruleMin,
  min_value as ruleMinValue,
  max as ruleMax,
  max_value as ruleMaxValue,
  numeric as ruleNumeric,
  regex as ruleRegex,
  required as ruleRequired,
  double as ruleDouble,
  size as ruleSize,
} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
// eslint-disable-next-line object-curly-newline
import { validatorCreditCard, validatorPassword, validatorPositive, validatorUrlValidator } from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', { ...ruleRequired, message: 'O campo {_field_} é obrigatório.' })

export const email = extend('email', { ...ruleEmail, message: 'Este endereço de email não é válido.' })

export const numeric = extend('numeric', { ...ruleNumeric, message: 'Só caracteres numéricos são permitidos.' })

export const size = extend('size', { ...ruleSize, message: 'O tamanho máximo permitido é de {size}kB' })

export const alphaSpaces = extend('alphaSpaces', {
  ...ruleAlphaSpaces,
  message: 'Só caracteres alfabéticos e espaços são permitidos.',
})

export const min = extend('min', {
  ...ruleMin,
  message: 'O valor mínimo permitido é {length}',
})

export const minValue = extend('minValue', {
  ...ruleMinValue,
  message: 'O valor mínimo permitido é {min}',
})

export const max = extend('max', {
  ...ruleMax,
  message: 'O tamanho máximo permitido é {length}',
})

export const maxValue = extend('maxValue', {
  ...ruleMaxValue,
  message: 'O valor máximo permitido é {max}',
})

export const double = extend('double', {
  ...ruleDouble,
  message: 'O valor deve ser decimal com {decimals} dígitos',
})

export const confirmed = extend('confirmed', { ...ruleConfirmed, message: 'Os campos não são iguais.' })

export const regex = extend('regex', { ...ruleRegex, message: 'Este valor não é válido' })

export const phone = extend('phone', {
  message: 'O telefone não é válido',
  validate: value => typeof value === 'string' && value.match(/\(+[0-9]+\)+\s[0-9]+-[0-9]{3,}/),
})

export const cep = extend('cep', {
  message: 'O CEP não é válido',
  validate: value => typeof value === 'string' && value.match(/[0-9]{5}[-][0-9]{3}/),
})

export const noAlpha = extend('noAlpha', {
  message: 'Não pode haver caracteres alfabéticos.',
  validate: value => typeof value === 'string' && value.match(/^[^a-zA-Z]*$/),
})

export const noNumber = extend('noNumber', {
  message: 'Não pode haver caracteres numéricos.',
  validate: value => typeof value === 'string' && value.match(/[^0-9.]/),
})

export const noSpecial = extend('noSpecial', {
  message: 'Não pode haver caracteres especiais.',
  validate: value => typeof value === 'string' && value.match(/^[A-Za-z0-9 ]+$/),
})

export const between = extend('between', ruleBetween)

export const alpha = extend('alpha', ruleAlpha)

export const integer = extend('integer', {
  ...ruleInteger,
  message: 'O valor deve ser um número inteiro',
})

export const digits = extend('digits', ruleDigits)

export const alphaDash = extend('alpha-dash', ruleAlphaDash)

export const alphaNum = extend('alpha-num', ruleAlphaNum)

export const length = extend('length', ruleLength)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Essa senha não é válida.',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'Essa URL não é válida.',
})

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
