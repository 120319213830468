import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import firebase from './firebase'
import servicos from './servicos'
import tarifas from './tarifas'
import movimentacao from './movimentacao'
import departamento from './departamento'
import cliente from './cliente'
import destinatario from './destinatario'
import remetente from './remetente'
import etiqueta from './etiquetas'
import imprimir from './imprimir-etiquetas'
import romaneios from './romaneios'
import pedidos from './pedidos'
import overviewEnvios from './overview-envios'
import faturas from './faturas'
import visualizar from './visualizar'
import usuario from './user'
import gerarEtiquetas from './gerar-etiqueta'
import cotacao from './cotacao'
import arquivoImportacao from './arquivo-importacao'
import layout from './layout'
import layoutParametro from './layout-parametro'
import clienteLayoutParametro from './cliente-layout-parametro'
import movimentacaoErro from './movimentacao-erro'
import relatorio from './relatorio'
import raioXServicos from './raio-x-servicos'
import integracao from './integracao'
import loja from './loja'
import tratativa from './tratativa'
import vendas from './vendas'
import movimentacaoEvento from './movimentacao-evento'
import currentUser from './current-user'
import logs from './logs'
import enderecoReversa from './endereco-reversa'
import trackExterno from './track-externo'
import pickup from './pickup'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    firebase,
    servicos,
    tarifas,
    movimentacao,
    departamento,
    cliente,
    destinatario,
    remetente,
    etiqueta,
    imprimir,
    romaneios,
    pedidos,
    overviewEnvios,
    faturas,
    visualizar,
    usuario,
    gerarEtiquetas,
    cotacao,
    arquivoImportacao,
    layout,
    layoutParametro,
    clienteLayoutParametro,
    movimentacaoErro,
    relatorio,
    raioXServicos,
    integracao,
    loja,
    tratativa,
    vendas,
    movimentacaoEvento,
    currentUser,
    logs,
    enderecoReversa,
    trackExterno,
    pickup,
  },
  strict: process.env.DEV,
})
